import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowRight } from 'react-feather'

export interface Props {
  image?: ImageProps
  pax?: string
  size?: string
  title: string
  URL: string
}

export const Room = memo(function Room({
  image,
  pax,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container to={URL}>
      {image ? <Image {...image} /> : null}
      <Wrapper className="room-wrapper">
        <Info dial={4} row>
          {size ? <Size>{size}</Size> : null}
          {pax ? <Pax>{pax}</Pax> : null}
        </Info>
        <Title>{`${title} _`}</Title>
      </Wrapper>
      <Arrow className="room-cta" dial={5}>
        <ArrowRight />
      </Arrow>
    </Container>
  )
})

const Container = styled(Link)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &:hover {
    &:before {
      opacity: 1;
    }
    img {
      transform: scale(1.1);
    }
    .room-wrapper {
      opacity: 0.5;
    }
    .room-cta {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark0, 0.4)},
      ${rgba(theme.colors.variants.neutralDark0, 0.8)}
    );
    backface-visibility: hidden;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 2.25rem;
  right: 2.25rem;
  left: 2.25rem;
  z-index: 2;
  transition: 0.3s ease-in-out;
`

const Title = styled.div`
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`

const Info = styled(FlexBox)`
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-bottom: 0.875rem;
  > div {
    display: inline-flex;
    align-items: center;
    &:nth-of-type(2) {
      margin-left: 0.375rem;
      &:before {
        content: '';
        width: 0.25rem;
        height: 0.25rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border-radius: 50%;
        margin-right: 0.375rem;
        transform: translateY(2px);
      }
    }
  }
`

const Size = styled.div``

const Pax = styled.div``

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }
`
