import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  languageCode: string
  rooms: RoomProps[]
}

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export const OtherRooms = memo(function OtherRooms({
  languageCode,
  rooms,
}: Props) {
  const settings = {
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: rooms.length > 1 ? 2 : 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: '36px',
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          centerPadding: '36px',
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  }

  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <Title>
          {useVocabularyData('peek-into-the-other-rooms', languageCode)}
        </Title>
      </Fade>
      <Fade bottom distance="3.75rem">
        <Slider {...settings}>
          {rooms.map((item, index) => (
            <Room key={index} {...item} />
          ))}
        </Slider>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 6rem 11.667vw 7.25rem;
  position: relative;
  &:before {
    content: '';
    width: 50%;
    height: calc(50% + 2.25rem);
    background: url('/wave.svg') left center no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    content: '';
    width: 100%;
    height: calc(12.778vw + 7.25rem);
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .react-reveal {
    position: relative;
    z-index: 2;
  }
  .slick-slider {
    margin-top: 3.75rem;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    width: 25.556vw;
    height: 25.556vw;
    position: relative;
  }

  @media (max-width: 1199px) {
    padding-right: 8.454vw;
    padding-left: 8.454vw;
  }

  @media (max-width: 1023px) {
    padding: 0 0 7.5rem;
    &:before,
    &:after {
      display: none;
    }

    .slick-slider {
      margin-top: 1.875rem;
    }
    .slick-slide {
      height: calc(50vw - 3rem);
      margin: 0 0.375rem;
    }
  }

  @media (max-width: 767px) {
    .slick-slide {
      height: calc(100vw - 5.25rem);
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;

  @media (max-width: 1023px) {
    text-align: center;
    padding: 0 8.454vw;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: -7.75rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    right: 6.5rem;
  }
  &.slider-next {
    right: 0;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
